import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-89f55762"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facility-booked-card__date__day" }
const _hoisted_2 = { class: "facility-booked-card__date__month" }
const _hoisted_3 = { class: "facility-booked-card__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { class: "facility-booked-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "facility-booked-card__date",
        style: _normalizeStyle('background:' + $props.booking.background)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString($props.booking.bookingMonthDay), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString($props.booking.bookingMonth), 1)
      ], 4),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_heading_3, { class: "facility-booked-card__text__facility text-capitalize" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.booking.facilityName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_subtitle_1, { class: "facility-booked-card__text__usage-time" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.booking.bookingTimes), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}