import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "booking-card__info"
}
const _hoisted_2 = { class: "booking-card__info__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingDetailsImage = _resolveComponent("BookingDetailsImage")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_cancel_booking = _resolveComponent("cancel-booking")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.confirmCancel)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 0,
          class: "booking-card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BookingDetailsImage, { facilityPhotos: $setup.facilityPhotos }, null, 8, ["facilityPhotos"]),
            _withDirectives(_createVNode(_component_base_button, {
              flat: "",
              round: "",
              icon: "far fa-close",
              color: "white",
              size: "sm",
              class: "close-button"
            }, null, 512), [
              [_directive_close_popup]
            ]),
            ($setup.facility)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_heading_3, { class: "booking-card__info__title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.facility.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_subtitle_1, { class: "booking-card__info__description" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.facility.description), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_heading_3, { class: "inline-block booking-card__info__label" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.check-in', 'Check in')) + ":  " + _toDisplayString(_ctx.$t('general.start-time', 'Start time') + "X") + ":  ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_subtitle_1, { class: "inline-block booking-card__info__text" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.booking.bookingCheckInTime), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_heading_3, { class: "inline-block booking-card__info__label" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.check-out', 'Check out')) + ":  " + _toDisplayString(_ctx.$t('general.end-time', 'End time') + "X") + ":  ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_subtitle_1, { class: "inline-block booking-card__info__text" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.booking.bookingCheckOutTime), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createVNode(_component_brand_button, {
                    class: "cancel-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.confirmCancel = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.cancel-booking', 'Cancel booking')) + " " + _toDisplayString(_ctx.$t('general.cancel', 'Cancel') + "X"), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.confirmCancel)
      ? (_openBlock(), _createBlock(_component_cancel_booking, {
          key: 1,
          bookingId: $setup.booking.id
        }, null, 8, ["bookingId"]))
      : _createCommentVNode("", true)
  ], 64))
}