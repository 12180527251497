
import BookingData from '@/models/BookingData';
export default{
	props: {
		booking: {
			type: BookingData,
			required: true
		}
	}
}
