import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4e7a842"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cancel-booking-card__content"
}
const _hoisted_2 = { class: "cancel-booking-card__actions" }
const _hoisted_3 = {
  key: 1,
  class: "cancel-booking-card__content"
}
const _hoisted_4 = { class: "cancel-booking-card__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_base_card = _resolveComponent("base-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_base_card, { class: "cancel-booking-card" }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_base_button, {
        flat: "",
        round: "",
        icon: "far fa-close",
        size: "sm",
        class: "close-button"
      }, null, 512), [
        [_directive_close_popup]
      ]),
      (!$setup.isCancelled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_heading_2, { class: "cancel-booking-card__title" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.cancel-booking', 'Cancel this booking')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_subtitle_1, { class: "cancel-booking-card__description" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(
						'general.cancel-booking-description',
						'Are you sure you want to cancel this reservation? You can rebook anytime.'
					)), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_brand_button, {
                class: "booking-item__actions__btn q-mr-sm",
                onClick: $setup.cancelBooking
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.yes-cancel-it', 'Yes, cancel it')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _withDirectives((_openBlock(), _createBlock(_component_base_button, {
                outline: "",
                class: "booking-item__actions__btn q-ml-xs"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.no-keep-it', 'No, keep it')), 1)
                ]),
                _: 1
              })), [
                [_directive_close_popup]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      ($setup.isCancelled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_heading_2, { class: "cancel-booking-card__title" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.booking-cancelled', 'Booking cancelled')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_subtitle_1, { class: "cancel-booking-card__description" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(
						'general.booking-cancelled-description',
						'Your booking was cancelled. You can always make a new booking.'
					)), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createBlock(_component_brand_button, { class: "booking-item__actions__btn" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.continue', 'Continue')), 1)
                ]),
                _: 1
              })), [
                [_directive_close_popup, 2]
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}