import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form__input-group" }
const _hoisted_2 = { class: "form__input-group" }
const _hoisted_3 = { class: "form__input-group" }
const _hoisted_4 = { class: "form__input-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_Field = _resolveComponent("Field")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Form = _resolveComponent("Form")!
  const _component_auth_card = _resolveComponent("auth-card")!

  return (_openBlock(), _createBlock(_component_auth_card, null, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('general.welcome-generic', 'Welcome to your resident portal')), 1)
    ]),
    subtitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t(
          'login.enter-your-credentials',
          'Enter your crendentials to access your account.'
        ) + "X"), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        "initial-values": $setup.initialValues,
        "validation-schema": $setup.schema,
        onSubmit: $setup.login
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_subtitle_1, { class: "form__input-group__label-text" }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, {
                  name: "envelope",
                  class: "q-mr-sm"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('general.email-address', 'Email') + "X"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Field, { name: "email" }, {
              default: _withCtx(({ errorMessage, value, field }) => [
                _createVNode(_component_base_input, _mergeProps({
                  "no-error-icon": "",
                  "model-value": value
                }, field, {
                  type: "email",
                  "error-message": errorMessage,
                  error: !!errorMessage
                }), null, 16, ["model-value", "error-message", "error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_subtitle_1, { class: "form__input-group__label-text" }, {
              default: _withCtx(() => [
                _createVNode(_component_base_icon, {
                  name: "lock",
                  class: "q-mr-sm"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('general.password', 'Password') + "X"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Field, { name: "password" }, {
              default: _withCtx(({ errorMessage, value, field }) => [
                _createVNode(_component_base_input, _mergeProps({
                  "no-error-icon": "",
                  "model-value": value
                }, field, {
                  type: $setup.isPwd ? 'password' : 'text',
                  "error-message": errorMessage,
                  error: !!errorMessage
                }), {
                  append: _withCtx(() => [
                    _createVNode(_component_base_icon, {
                      name: $setup.isPwd ? 'eye' : 'eye-slash',
                      class: "cursor-pointer fa-xs",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isPwd = !$setup.isPwd))
                    }, null, 8, ["name"])
                  ]),
                  _: 2
                }, 1040, ["model-value", "type", "error-message", "error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_brand_button, {
              type: "submit",
              class: "form__submit-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('login.log-in', 'Sign in') + "X"), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_subtitle_1, { class: "form__subtitle text-center no-margin" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.forget-password-msg', 'Forgot your password?')) + " ", 1),
                _createVNode(_component_router_link, {
                  to: "reset",
                  class: "form__redirect"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.reset-msg', 'Reset it here')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["initial-values", "validation-schema", "onSubmit"])
    ]),
    _: 1
  }))
}