/* eslint-disable */

import axios from 'axios';
import store from '@/store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import endpoints from '@/api/endpoints';
import Mutations from '../mutations';
import Actions from '../actions';
import ErrorResponse from '@/models/ErrorResponse';


@Module({namespaced: true})
export default class Localization extends VuexModule {
    public localizationKey = 'en';
    public allLanguages: any[] = [];

    get getAllLanguages() {
        return this.allLanguages;
    }

    @Action
    public FETCH_ALL_LANGUAGES() {
        return axios.get(`https://api.hococo.io/api/all-languages`)
            .then((response: any) => {
                store.commit(Mutations.SET_ALL_LANGUAGES, response.data);
            })
            .catch((error) => {
                if (error.response) {
                    const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
                    store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
                } else {
                    console.log(error);
                }
            });
    }

    // @Action
    // public FETCH_LANGUAGE_ALL_TEXTS(data: any) {
    //     axios.get(endpoints.Localization.LANGUAGE_TEXTS, {params: {key: data.key, search: data.search}})
    //         .then((response: any) => {
    //             store.commit(Mutations.SET_LANGUAGE_ALL_TEXTS, response.data);
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
    //                 store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
    //             } else {
    //                 console.log(error);
    //             }
    //         });
    // }

    @Action
    public UPDATE_USER_LANGUAGE(languageKey: string) {
        axios.get(`https://api.hococo.io/api/language/${languageKey}/texts`)
            .then((response) => {
                // @ts-ignore
                if (store.state.i18n.translations.hasOwnProperty(response.data.language.key)) {
                    store.dispatch('i18n/replaceLocale', {
                        locale: response.data.language.key,
                        translations: response.data.texts,
                    });
                } else {
                    store.dispatch('i18n/addLocale', {
                        locale: response.data.language.key,
                        translations: response.data.texts,
                    });
                }

                store.commit(Mutations.SET_LOCALIZATION_KEY, response.data.language.key);
                store.dispatch('i18n/setLocale', {locale: languageKey});
            });
    }

    @Mutation
    public SET_ALL_LANGUAGES(data: any) {
        this.allLanguages = data;
    }

    @Mutation
    public SET_LOCALIZATION_KEY(value: string) {
        this.localizationKey = value;
    }
}

