import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a036c7f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-avatar" }
const _hoisted_2 = { class: "profile-avatar__content" }
const _hoisted_3 = { class: "profile-avatar__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_avatar = _resolveComponent("base-avatar")!
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_avatar, {
      class: "profile-avatar__image",
      src: $setup.profileImage,
      size: "120px"
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_heading_2, { class: "profile-avatar__content__header" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.profile', 'Profile') + "X"), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_subtitle_1, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(
						'general.update-photo-msg',
						'Update your photo and personal information.'
					)), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.saveNewProfileImage && $setup.saveNewProfileImage(...args))),
        ref: "avatarSelector"
      }, null, 544),
      _createVNode(_component_brand_button, {
        class: "add-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.avatarSelector.click()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.upload-new-photo', 'Upload new photo')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_base_button, {
        class: "delete-btn",
        outline: "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.deleteProfileImage()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.delete', 'Delete') + "X"), 1)
        ]),
        _: 1
      })
    ])
  ]))
}