
import { useStore } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import 'yup-phone';
import { computed } from 'vue';

import Actions from '@/store/actions';
import Mutations from '@/store/mutations';
import ProfileAvatarPicker from './components/ProfileAvatarPicker.vue';
import Separator from '@/components/common/Separator.vue';

export default {
	components: {
		Form,
		ProfileAvatarPicker,
		Separator,
	},
	setup() {
		const store = useStore();

		const schema = yup.object({
			firstName: yup.string().required().label('First name'),
			familyName: yup.string().required().label('Family name'),
			phoneNumber: yup.string().phone().label('Phone number'),
			email: yup.string().required().email().label('Email'),
			zipCode: yup.string().required().label('Zip code'),
			city: yup.string().required().label('City'),
			address: yup.string().required().label('Address'),
		});

		const userData = computed(() => {
			return store.state.User.personData;
		});

		function onSubmit(values) {
			store.dispatch(Actions.UPDATE_USER, values).then(() => {
				store.commit(Mutations.UPDATE_PERSON_DATA, values);
			});
		}

		return {
			onSubmit,
			schema,
			userData,
		};
	},
};
