import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4844d19c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-container" }
const _hoisted_2 = { class: "profile-card__header" }
const _hoisted_3 = { class: "q-pt-lg q-px-md" }
const _hoisted_4 = { class: "row q-col-gutter-md" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "col-6 pt-10" }
const _hoisted_8 = { class: "col-6 pt-10" }
const _hoisted_9 = { class: "col-6 pt-10" }
const _hoisted_10 = { class: "col-6 pt-10" }
const _hoisted_11 = { class: "col-6 pt-10" }
const _hoisted_12 = { class: "profile-card__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileAvatarPicker = _resolveComponent("ProfileAvatarPicker")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_separator = _resolveComponent("separator")!
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_input_with_validation = _resolveComponent("input-with-validation")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_card, { class: "profile-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, { class: "q-py-none" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ProfileAvatarPicker, {
                modelValue: _ctx.profileImage,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileImage) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_separator),
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            ($setup.userData)
              ? (_openBlock(), _createBlock(_component_Form, {
                  key: 0,
                  "initial-values": $setup.userData,
                  "validation-schema": $setup.schema,
                  onSubmit: $setup.onSubmit
                }, {
                  default: _withCtx(({ meta }) => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_heading_2, { class: "q-mb-lg" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.personal-info', 'Personal info')), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.first-name', 'First name') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "firstName" })
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.family-name', 'Family name') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "familyName" })
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.phone', 'Phone') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "phoneNumber" })
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.email', 'Email') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "email" })
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.address', 'Address') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "address" })
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.city', 'City') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "city" })
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_subtitle_1, { class: "profile-card-input-group__label-text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.zip-code', 'Zip code') + "X"), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_input_with_validation, { name: "zipCode" })
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_base_button, {
                        class: "primary-btn",
                        type: "submit",
                        disable: !meta.valid
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.save-changes', 'Save changes')), 1)
                        ]),
                        _: 2
                      }, 1032, ["disable"]),
                      _createVNode(_component_base_button, {
                        class: "primary-btn",
                        type: "submit",
                        disable: !meta.valid
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.save-changes', 'Save changes')), 1)
                        ]),
                        _: 2
                      }, 1032, ["disable"])
                    ])
                  ]),
                  _: 1
                }, 8, ["initial-values", "validation-schema", "onSubmit"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}