import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31b20430"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "booking-item" }
const _hoisted_2 = { class: "booking-item__info" }
const _hoisted_3 = { class: "booking-item__info__date__day" }
const _hoisted_4 = { class: "booking-item__info__text" }
const _hoisted_5 = { class: "booking-item__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_cancel_booking = _resolveComponent("cancel-booking")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "booking-item__info__date",
          style: _normalizeStyle('background:' + $props.booking.background)
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString($setup.getLocalShortDate($props.booking.startTime)), 1)
        ], 4),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_heading_3, { class: "booking-item__info__text__facility text-capitalize" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.booking.facilityName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_subtitle_1, { class: "booking-item__info__text__usage-time" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString($setup.getLocalTime($props.booking.startTime)) + " - " + _toDisplayString($setup.getLocalTime($props.booking.endTime)), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_brand_button, {
          class: "q-mr-sm booking-item__actions__btn",
          onClick: $setup.redirectToBookingDetail
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.view', 'View')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_base_button, {
          outline: "",
          class: "booking-item__actions__btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.confirmCancel = true)),
          size: "xs"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.cancel', 'Cancel') + "X"), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_base_modal, {
      modelValue: $setup.confirmCancel,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.confirmCancel) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cancel_booking, {
          bookingId: $props.booking.id
        }, null, 8, ["bookingId"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}